'use client';

import Link from 'next/link';
import type { LinkProps } from 'next/link';
import type { FC, AnchorHTMLAttributes } from 'react';
import { useSiteContext } from '@/context/SiteContext';
import { removeLeadingSlash } from '@/utils/remove-leading-slash';

type InternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps;

export const InternalLink: FC<InternalLinkProps> = ({
	children,
	href,
	...props
}) => {
	const { host } = useSiteContext();

	// When localhost links are being used, use http instead of https
	const baseHost = host.includes('localhost')
		? `http://${host}`
		: `https://${host}`;

	/**
	 * This code is to check if the API was delivering links to the BE WP instance and replace with correct FE links
	 */
	const isAbsoluteUrl =
		(href as string).startsWith('http://') ||
		(href as string).startsWith('https://');
	let hrefHost = '';

	try {
		hrefHost = new URL(href as string).host;
	} catch (error) {
		// Ignore
	}

	let localLink = href;
	if (
		isAbsoluteUrl &&
		(href.includes('fastly.thearenagroup.net') || hrefHost === host)
	) {
		// For site host or fastly urls, replace with the base host
		const url = new URL(href as string);
		localLink = `${baseHost}/${removeLeadingSlash(url.pathname)}${url.hash}`;
	}

	if (!href.includes('saymedia') && !isAbsoluteUrl) {
		localLink = href;
	}

	return (
		<Link {...props} href={localLink}>
			{children}
		</Link>
	);
};
